[
  {
    "id": 1,
    "title": "Pledge with no reward",
    "description": "Choose to support us without a reward if you simply believe in our project. As a backer, you will be signed up to receive product updates via email.",
    "remain": 999999,
    "price" : 0
  },
  {
    "id": 2,
    "title": "Bamboo Stand",
    "description": "You get an ergonomic stand made of natural bamboo. You've helped us launch our promotional campaign, and you’ll be added to a special Backer member list.",
    "remain": 101,
    "price" : 25
  },
  {
    "id": 3,
    "title": "Black Edition Stand",
    "description": "You get a Black Special Edition computer stand and a personal thank you. You’ll be added to our Backer member list. Shipping is included.",
    "remain": 64,
    "price" : 75
  },
  {
    "id": 4,
    "title": "Mahogany Special Edition",
    "description": "You get two Special Edition Mahogany stands, a Backer T-Shirt, and a personal thank you. You’ll be added to our Backer member list. Shipping is included.",
    "remain": 0,
    "price" : 200
  }
]
